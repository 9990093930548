'use client';

import { Button, Card, Header, InnerContent } from '@/components';
import { useRouter } from 'next/navigation';

export default function Error() {
  const router = useRouter();

  return (
    <div className="relative py-4 xl:py-12">
      <InnerContent>
        <Card>
          <div className="flex flex-col items-center xl:p-32 gap-8 text-center">
            <Header as="h4" variant="secondary">
              500
            </Header>
            <Header as="h1" className="text-center" variant="primary">
              Something went wrong
            </Header>
            <p>Sorry, something went wrong trying to load this page.</p>
            <Button onClick={() => router.push('/')}>Go back home</Button>
          </div>
        </Card>
      </InnerContent>
    </div>
  );
}
